import {useTranslation} from 'react-i18next';
import GlobalSettings from '../GlobalSettings';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import Logo from '../../Logo';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as MdIcons from "react-icons/md";
import * as GiIcons from "react-icons/gi";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import * as FcIcons from "react-icons/fc";
import * as SiIcons from "react-icons/si";

import logo_full_name from '../../../images/logo/rdct.webp';

const FooterMain = () => {
    const MainContainer = styled.div`
    background: ${GlobalSettings.mainDarkColor};
    padding-bottom: 64px;
    padding-top: 64px;

    padding-right: 64px;
    padding-left: 64px;

    @media screen and (max-width: 1400px) {
        padding-right: 64px;
        padding-left: 64px;
    }

    @media screen and (max-width: 768px) {
        padding-right: 34px;
        padding-left: 34px;
    }

    @media screen and (max-width: 480px) {
        padding-right: 14px;
        padding-left: 14px;
    }
`;

  const ContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* max-width: 100%; */
  `;

  const Column = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    /* padding: 20px; */
  `;

  const Row = styled.div`
    display: grid;
    align-items: top;
    grid-gap: 32px;


    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media screen and (max-width: 1400px) {
      grid-template-columns: 1fr 1fr 1fr; 
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr; 
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  `;

  const ALink = styled.a`
    color: ${GlobalSettings.mainWhiteColor};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
    /* text-justify:center; */
    text-decoration: none;

    &:hover {
      color: ${GlobalSettings.mainYellowColor};
      cursor: pointer;
    }
  `;

  const NLink = styled(Link)`
    color: ${GlobalSettings.mainWhiteColor};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: center;
    /* text-justify: center; */
    text-decoration: none;

    &:hover {
      color: ${GlobalSettings.mainYellowColor};
      cursor: pointer;
    }
  `;

  const LogoWrapper = styled.a`
    transition: opacity 0.2s ease;
    &:hover {
        cursor: pointer;
        opacity: 0.6;
    }
  `;

  const LogoImage = styled.img`
    height: 55px;
    width: 55px;
  `;


  const Title = styled.span`
    color: ${GlobalSettings.mainWhiteColor};
    font-weight: bold;
    margin-top: ${GlobalSettings.marginAroundHTMLElements};
    margin-bottom: ${GlobalSettings.marginAroundHTMLElements};
    /* text-transform: uppercase; */
    font-size: 16pt;
    
    @media screen and (max-width: 1023px) {
      font-size: 14pt;
    }

    @media screen and (max-width: 768px) {
      font-size: 12pt; 
    }

    @media screen and (max-width: 480px) {
      font-size: 11pt;
    }
  `;


  const Text = styled.p`
    color: ${GlobalSettings.mainWhiteColor};
    margin-top: ${GlobalSettings.marginAroundHTMLElements};
    margin-bottom: ${GlobalSettings.marginAroundHTMLElements};
  `;


  const LinkName = styled.span`
    margin: 0px;
    padding: 0px;
    margin-left: ${GlobalSettings.marginAroundHTMLElements};
  `;

  function FooterMainContainer({children, ...restProps}) {
    return <MainContainer {...restProps}>{children}</MainContainer>
  }

  FooterMainContainer.ContainerWrapper = function FooterMainContainerContainerWrapper({children, ...restProps}) {
    return <ContainerWrapper {...restProps}>{children}</ContainerWrapper>
  }

  FooterMainContainer.Row = function FooterMainContainerRow({children, ...restProps}) {
    return <Row {...restProps}>{children}</Row>
  }

  FooterMainContainer.Column = function FooterMainContainerColumn({children, ...restProps}) {
    return <Column {...restProps}>{children}</Column>
  }

  FooterMainContainer.ALink = function FooterMainContainerALink({children, ...restProps}) {
    return <ALink {...restProps}>{children}</ALink>
  }

  FooterMainContainer.NLink = function FooterMainContainerNLink({ children, ...restProps }) {
    return <NLink {...restProps}>{children}</NLink>
  }

  FooterMainContainer.Title = function FooterMainContainerTitle({children, ...restProps}) {
    return <Title {...restProps}>{children}</Title>
  }

  FooterMainContainer.Text = function FooterMainContainerText({children, ...restProps}) {
    return <Text {...restProps}>{children}</Text>
  }

  const {t} = useTranslation('header_footer');

  return (
      <FooterMainContainer>
        <FooterMainContainer.ContainerWrapper>
           <FooterMainContainer.Row>

            <FooterMainContainer.Column>
                <FooterMainContainer.Title>{t('rdct_menu')}</FooterMainContainer.Title>
                <FooterMainContainer.NLink to={t('home_canonical_url')}> <AiIcons.AiFillHome size='1.0em'/> <LinkName>{t('home_menu')}</LinkName> </FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('about_menu.canonical_url')}> <FcIcons.FcAbout size='1.0em'/> <LinkName>{t('about_menu.title')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('history_menu.canonical_url')}> <AiIcons.AiOutlineHistory size='1.0em'/><LinkName>{t('history_menu.title')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('internship_canonical_url')}> <MdIcons.MdOutlineSchool size='1.0em' /><LinkName>{t('internship_program_menu')}</LinkName></FooterMainContainer.NLink>
            </FooterMainContainer.Column>

            <FooterMainContainer.Column>
                <FooterMainContainer.Title>{t('research_menu')}</FooterMainContainer.Title>
                <FooterMainContainer.NLink to={t('web_technologies_menu.canonical_url')}> <SiIcons.SiSemanticweb size='1.0em' /><LinkName>{t('web_technologies_menu.title')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('digital_marketing_menu.canonical_url')}> <GiIcons.GiDigitalTrace size='1.0em' /><LinkName>{t('digital_marketing_menu.title')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('management_menu.canonical_url')}> <MdIcons.MdOutlineManageAccounts size='1.0em' /><LinkName>{t('management_menu.title')}</LinkName></FooterMainContainer.NLink>
            </FooterMainContainer.Column>  

            <FooterMainContainer.Column>
                <FooterMainContainer.Title>{t('solution_menu')}</FooterMainContainer.Title>
                <FooterMainContainer.NLink to={t('support_for_visa_menu.canonical_url')}> <GiIcons.GiHelp size='1.0em' /><LinkName>{t('support_for_visa_menu.title')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('consulting_services_menu.canonical_url')}> <SiIcons.SiConsul size='1.0em' /><LinkName>{t('consulting_services_menu.title')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('digital_cosmetics_menu.canonical_url')}> <SiIcons.SiConsul size='1.0em' /><LinkName>{t('digital_cosmetics_menu.title')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.ALink href="https://clean.rdct.tech/"> <MdIcons.MdCleaningServices size='1.0em' /><LinkName>{t('clean_agency_menu.title')}</LinkName></FooterMainContainer.ALink>
                <FooterMainContainer.ALink href="http://booksharing.rdct.tech/"> <MdIcons.MdLibraryBooks size='1.0em' /><LinkName>{t('book_sharing_menu.title')}</LinkName></FooterMainContainer.ALink>
            </FooterMainContainer.Column>

            <FooterMainContainer.Column>
                <FooterMainContainer.Title>{t('formations_menu')}</FooterMainContainer.Title>
                <FooterMainContainer.NLink to={t('training_web_development_frontend_menu.canonical_url')}> <RiIcons.RiReactjsFill size='1.0em' /><LinkName>{t('training_web_development_frontend_menu.title')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('training_web_development_backend_menu.canonical_url')}> <AiIcons.AiOutlineConsoleSql size='1.0em' /><LinkName>{t('training_web_development_backend_menu.title')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('training_search_engine_optimization_menu.canonical_url')}> <AiIcons.AiOutlineFileSearch size='1.0em' /><LinkName>{t('training_search_engine_optimization_menu.title')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('training_digital_marketing_menu.canonical_url')}> <GiIcons.GiDigitalTrace size='1.0em' /><LinkName>{t('training_digital_marketing_menu.title')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('training_microsoft_office_menu.canonical_url')}> <SiIcons.SiMicrosoftword size='1.0em' /><LinkName>{t('training_microsoft_office_menu.title')}</LinkName></FooterMainContainer.NLink>
            </FooterMainContainer.Column> 

            <FooterMainContainer.Column>
                <FooterMainContainer.Title>{t('statements_menu')}</FooterMainContainer.Title>
                <FooterMainContainer.NLink to={t('terms_canonical_url')}> <RiIcons.RiShieldUserLine size='1.0em' /><LinkName>{t('terms_of_use_menu')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('privacy_canonical_url')}> <RiIcons.RiGitRepositoryPrivateFill size='1.0em' /><LinkName>{t('privacy_statement_menu')}</LinkName></FooterMainContainer.NLink>
                <FooterMainContainer.NLink to={t('cookie_canonical_url')}> <BiIcons.BiCookie size='1.0em' /><LinkName>{t('cookies_statement_menu')}</LinkName></FooterMainContainer.NLink>
            </FooterMainContainer.Column>

            <FooterMainContainer.Column>
              <FooterMainContainer.Title>{t('social_media_menu')}</FooterMainContainer.Title>
              <FooterMainContainer.ALink href="https://api.whatsapp.com/send?phone=237694060524"> <FaIcons.FaWhatsappSquare size='1.0em'/> <LinkName>WhatsApp</LinkName></FooterMainContainer.ALink>
              <FooterMainContainer.ALink href="https://www.linkedin.com/company/info-rdct"> <BsIcons.BsLinkedin size='1.0em'/> <LinkName>LinkedIn</LinkName></FooterMainContainer.ALink>
              <FooterMainContainer.ALink href="https://www.facebook.com/info.rdct"> <AiIcons.AiFillFacebook size='1.0em'/> <LinkName>Facebook</LinkName></FooterMainContainer.ALink>
              <FooterMainContainer.ALink href="https://www.instagram.com/info.rdct"> <FaIcons.FaInstagramSquare size='1.0em'/> <LinkName>Instagram</LinkName></FooterMainContainer.ALink>
              <FooterMainContainer.ALink href="https://www.youtube.com/channel/UCts34-0bSMyXsQVIaFs2T1w"> <IoIcons.IoLogoYoutube size='1.0em'/> <LinkName>YouTube</LinkName></FooterMainContainer.ALink>
              <FooterMainContainer.ALink href="https://twitter.com/InfoRdct"> <AiIcons.AiFillTwitterSquare size='1.0em'/> <LinkName>Twitter</LinkName></FooterMainContainer.ALink>
            </FooterMainContainer.Column>

            <FooterMainContainer.Column>
              <FooterMainContainer.Title>Contacts</FooterMainContainer.Title>
              <FooterMainContainer.Text> <BsIcons.BsFillTelephoneFill size='1.0em'/> <LinkName>(+237) 682634355</LinkName></FooterMainContainer.Text>
              <FooterMainContainer.Text> <BsIcons.BsFillTelephoneFill size='1.0em' /> <LinkName>(+237) 694060524</LinkName></FooterMainContainer.Text>
              <FooterMainContainer.Text> <BsIcons.BsFillTelephoneFill size='1.0em' /> <LinkName>(+237) 233392501</LinkName></FooterMainContainer.Text>
              <FooterMainContainer.Text> <MdIcons.MdOutlineMailOutline size='1.0em'/> <LinkName>info@rdct.tech</LinkName></FooterMainContainer.Text>
              <FooterMainContainer.ALink href="https://g.page/r/CfM7vZT4bsaSEAE?gm"> <GiIcons.GiPositionMarker size='1.0em'/> <LinkName>Google map</LinkName></FooterMainContainer.ALink>
            </FooterMainContainer.Column>

            <FooterMainContainer.Column>
              <LogoWrapper href={t('home_canonical_url')}>
                <LogoImage src={logo_full_name} alt="rdct-logo" loading="eager" width="55px" height="55px"/>
              </LogoWrapper>
              <FooterMainContainer.Text>{t('rdct_name')}</FooterMainContainer.Text>
              <FooterMainContainer.Text>BP: 12410 Douala</FooterMainContainer.Text>
              <FooterMainContainer.Text>{t('located_in')} Mambanda, Bonabéri, Douala, {t('location_country')}</FooterMainContainer.Text>
              <FooterMainContainer.Text>{t('open_days')} {t('open_days_value')}</FooterMainContainer.Text>
              <FooterMainContainer.Text>{t('open_hours')} {t('open_hours_value')}</FooterMainContainer.Text>
              <FooterMainContainer.Text>{t('close_days')} {t('close_days_value')}</FooterMainContainer.Text>
            </FooterMainContainer.Column>

          </FooterMainContainer.Row>
        </FooterMainContainer.ContainerWrapper>
      </FooterMainContainer>
  )
}

const Footer = () => {
  return (
    <FooterMain/>
  );
};

export default Footer;